<template>
  <main>
    <PageTitleContainer>
      <PageTitle removeMargin>Aggiungi distributore</PageTitle>

      <div class="mb-3 text-right">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'suppliers.dealers' }">Torna indietro</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <DealerForm :value="dealer" :feedback.sync="feedback" @submitForm="saveDealer" />
    </div>
  </main>
</template>

<script>

import PageTitle from '@/views/components/Private/PageTitle.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import DealerForm from '@/views/components/Form/DealerForm.vue';

export default {
  components: {
    PageTitle,
    PageTitleContainer,
    DealerForm,
  },
  data () {
    return {
      dealer: null,
      feedback: null,
    };
  },
  methods: {
    success () {
      this.feedback = true;
      this.$router.replace({ name: 'suppliers.dealers' });
    },
    failed (err) {
      this.feedback = false;
      this.$log.error(err);
    },
    saveDealer (dealerForm) {
      const dealer = { ...dealerForm };

      this.$api.createDealer(dealer)
        .then(() => this.success())
        .catch(err => this.failed(err))
      ;
    },
  },
};

</script>
